<template>
  <div class="w-screen min-h-screen h-max flex justify-center bg-black overflow-x-hidden">
    <div
      class="flex flex-col items-start justify-center h-full my-5 space-y-2"
      :style="`width: ${resizedScreen}px;`"
    >
      <div class="md:fixed -bottom-0 -left-0 md:m-4">
        <BackButton />
      </div>
      <canvas
        class="border border-gray-300 p-2 rounded-md"
        ref="sinCanvas"
        :style="`width: ${resizedScreen}px; height: ${resizedScreen}px;`"
      ></canvas>
      <p class="italic text-gray-400 text-sm">
        Es una representación modificada de una onda senoidal.
      </p>
      <SinParameters
        ref="sinParameters"
        @update-parameters="handleUpdateParameters"
        @request-initial-values="provideInitialValues"
      />
      <div class="w-full text-gray-300 font-thin">
        <div
          @mouseover="isTitleHovered = true"
          @mouseleave="isTitleHovered = false"
          class="transition duration-300 ease-in-out hover:text-white cursor-pointer border border-gray-300 hover:border-white rounded-md px-2 p-[2px]"
        >
          <p v-if="!isTitleHovered">
            x = amplitude ⋅ sin( {{ multiplier }}π ⋅ y / canvasHeight + time)
          </p>
          <p v-else>
            x = {{ amplitude }} ⋅ sin( {{ multiplier }}π ⋅ y / {{ resizedScreen }} +
            {{ time }})
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue";
import SinParameters from "./Partials/SinParameters.vue";
export default {
  components: {
    SinParameters,
    BackButton,
  },
  data() {
    return {
      resizedScreen: "",
      isTitleHovered: false,
      lines: [],
      amplitude: 50,
      time: 0,
      multiplier: 1.0,
      maxLines: 10,
      interval: 100,
      lineWidth: 1,
      strokeColor: "#999e9d",
      intervalId: null,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.startInterval();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    startInterval() {
      this.stopInterval();

      this.intervalId = setInterval(() => {
        this.time += 0.1;
        this.generateLine();
      }, this.interval);
    },
    stopInterval() {
      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    generateLine() {
      const canvas = this.$refs.sinCanvas;
      if (canvas === null) return;
      const ctx = canvas.getContext("2d");

      const newLine = this.drawSineWave(ctx, this.amplitude, this.time);

      this.lines.push(newLine);

      while (this.lines.length > this.maxLines) {
        this.lines.shift();
      }

      this.renderLines();
    },
    drawSineWave(ctx, amplitude, time) {
      const canvasWidth = ctx.canvas.width;
      const canvasHeight = ctx.canvas.height;

      const line = [];

      ctx.beginPath();
      ctx.lineWidth = this.lineWidth;
      ctx.strokeStyle = this.strokeColor;

      for (let y = 0; y <= canvasHeight; y += 1) {
        const x =
          amplitude * Math.sin((this.multiplier * Math.PI * y) / canvasHeight + time);
        ctx.lineTo(x + canvasWidth / 2, y);
        line.push({ x: x + canvasWidth / 2, y });
      }

      ctx.stroke();
      this.addShadow(ctx);

      return line;
    },
    addShadow(ctx) {
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
    },
    renderLines() {
      const canvas = this.$refs.sinCanvas;
      const ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (const line of this.lines) {
        ctx.beginPath();
        ctx.lineWidth = this.lineWidth;
        ctx.strokeStyle = this.strokeColor;

        for (const point of line) {
          ctx.lineTo(point.x, point.y);
        }

        ctx.stroke();
        this.addShadow(ctx);
      }
    },
    handleResize() {
      if (window.innerWidth < 420) {
        this.resizedScreen = `${window.innerWidth - 20}`;
      } else {
        this.resizedScreen = "400";
      }
    },
    handleUpdateParameters(newParameters) {
      if (this.interval != newParameters.interval.value) {
        this.interval = newParameters.interval.value;
        this.startInterval();
      }

      this.amplitude = newParameters.amplitude.value;
      this.multiplier = newParameters.multiplier.value;
      this.maxLines = newParameters.maxLines.value;
      this.lineWidth = newParameters.lineWidth.value;
      this.strokeColor = newParameters.strokeColor.value;
    },
    provideInitialValues() {
      this.$refs.sinParameters.setInitialValues({
        amplitude: this.amplitude,
        multiplier: this.multiplier,
        maxLines: this.maxLines,
        interval: this.interval,
        lineWidth: this.lineWidth,
      });
    },
  },
};
</script>

<style>
body {
  overflow-x: hidden;
}
</style>
