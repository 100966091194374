import { createRouter, createWebHistory } from 'vue-router';
import App from './App/Show.vue';
import SineWave from './SineWave/Show.vue';
import Cube from './Cube/Show.vue';

const routes = [
  {
    path: '/001',
    component: SineWave,
  },
  {
    path: '/002',
    component: Cube,
  },
  {
    path: '/',
    component: App,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;