<template>
  <div class="w-screen min-h-screen h-max flex justify-center bg-black overflow-x-hidden">
    <div
      class="fixed top-[20%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-white"
    >
      <h1 class="font-mono text-lg font-bold">VueMathLab</h1>
      <pre class="grid gap-x-4 gap-y-1 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-4">
        <a href="/001" class="block mr-4 hover:underline"><span class="mr-2 opacity-50">001</span><b class="opacity-90">SineWave</b></a>
        <a href="/002" class="block mr-4 hover:underline"><span class="mr-2 opacity-50">002</span><b class="opacity-90">Cube</b></a>
      </pre>
    </div>
  </div>
</template>
