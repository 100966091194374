<template>
  <div class="w-screen min-h-screen h-max flex justify-center bg-black overflow-x-hidden">
    <div
      class="flex flex-col items-start justify-center h-full my-5 space-y-2"
      :style="`width: ${resizedScreen}px;`"
    >
      <div class="md:fixed -bottom-0 -left-0 md:m-4">
        <BackButton />
      </div>
      <div
        class="flex items-center justify-center pb-4 border border-gray-300 p-2 rounded-md text-xs cursor-default"
        :style="`width: ${resizedScreen}px; height: ${resizedScreen}px; color: ${strokeColor};`"
      >
        <pre>{{ display }}</pre>
      </div>
      <p class="italic text-gray-400 text-sm">
        Motor de renderización 3D básico con ASCII
      </p>
      <CubeParameters
        ref="cubeParameters"
        @update-parameters="handleUpdateParameters"
        @request-initial-values="provideInitialValues"
      />
      <div
        v-html="renderedMath"
        class="w-full text-white break-before-auto text-xs transition duration-300 ease-in-out hover:text-white cursor-pointer border border-gray-300 hover:border-white rounded-md px-2 p-[2px]"
      ></div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import "katex/dist/katex.min.css";
import katex from "katex";
import CubeParameters from "./Partials/CubeParameters.vue";
import BackButton from "@/components/BackButton.vue";

export default {
  components: {
    CubeParameters,
    BackButton,
  },
  data() {
    return reactive({
      overlapse: false,
      cubeWidth: 10,
      width: 0,
      height: 0,
      zBuffer: new Array(0 * 0).fill(0),
      buffer: new Array(0 * 0).fill(" "),
      backgroundASCIICode: " ",
      distanceFromCam: 110,
      horizontalOffset: 0,
      K1: 50,
      incrementSpeed: 0.8,
      A: 0,
      B: 0,
      C: 0,
      display: "",
      resizedScreen: "",
      renderedMath: "",
      mathExpression: `
      \\text{Rotación en } x (A): \\\\
        x' = x \\\\
        y' = y \\cdot \\cos(A) - z \\cdot \\sin(A) \\\\
        z' = y \\cdot \\sin(A) + z \\cdot \\cos(A) \\\\
        \\\\
        \\text{Rotación en } y (B): \\\\
        x'' = x' \\cdot \\cos(B) + z' \\cdot \\sin(B) \\\\
        y'' = y' \\\\
        z'' = -x' \\cdot \\sin(B) + z' \\cdot \\cos(B) \\\\
        \\\\
        \\text{Rotación en } z (C): \\\\
        x''' = x'' \\cdot \\cos(C) - y'' \\cdot \\sin(C) \\\\
        y''' = x'' \\cdot \\sin(C) + y'' \\cdot \\cos(C) \\\\
        z''' = z'' \\\\
        \\\\
        \\text{Proyección en Perspectiva:} \\\\
        xp = \\frac{K1 \\cdot x'''}{z''' + \\text{distanceFromCam}} \\\\
        yp = \\frac{K1 \\cdot y'''}{z''' + \\text{distanceFromCam}}
      `,
      strokeColor: "#c9c9c9",
    });
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 420) {
        this.resizedScreen = `${window.innerWidth - 20}`;
      } else {
        this.resizedScreen = "400";
      }

      this.calculateCubeSize();
    },
    calculateCubeSize() {
      const initialWidth = 400;
      const initialHeight = 400;

      const currentWidth = this.resizedScreen;
      const currentHeight = this.resizedScreen;

      const newWidth = Math.round((currentWidth / initialWidth) * 60);
      const newHeight = Math.round((currentHeight / initialHeight) * 25);

      const heightRatio = currentHeight / initialHeight;
      const newDistanceFromCam = Math.round((1 / heightRatio) * 80);

      this.width = newWidth;
      this.height = newHeight;
      this.distanceFromCam = newDistanceFromCam;
      this.zBuffer = new Array(newWidth * newHeight).fill(0);
      this.buffer = new Array(newWidth * newHeight).fill(".");
    },
    calculateX(i, j, k) {
      return (
        j * Math.sin(this.A) * Math.sin(this.B) * Math.cos(this.C) -
        k * Math.cos(this.A) * Math.sin(this.B) * Math.cos(this.C) +
        j * Math.cos(this.A) * Math.sin(this.C) +
        k * Math.sin(this.A) * Math.sin(this.C) +
        i * Math.cos(this.B) * Math.cos(this.C)
      );
    },
    calculateY(i, j, k) {
      return (
        j * Math.cos(this.A) * Math.cos(this.C) +
        k * Math.sin(this.A) * Math.cos(this.C) -
        j * Math.sin(this.A) * Math.sin(this.B) * Math.sin(this.C) +
        k * Math.cos(this.A) * Math.sin(this.B) * Math.sin(this.C) -
        i * Math.cos(this.B) * Math.sin(this.C)
      );
    },
    calculateZ(i, j, k) {
      return (
        k * Math.cos(this.A) * Math.cos(this.B) -
        j * Math.sin(this.A) * Math.cos(this.B) +
        i * Math.sin(this.B)
      );
    },
    calculateForSurface(cubeX, cubeY, cubeZ, ch) {
      const x = this.calculateX(cubeX, cubeY, cubeZ);
      const y = this.calculateY(cubeX, cubeY, cubeZ);
      const z = this.calculateZ(cubeX, cubeY, cubeZ) + this.distanceFromCam;

      const ooz = 1 / z;

      const xp = Math.floor(
        this.width / 2 + this.horizontalOffset + this.K1 * ooz * x * 2
      );
      const yp = Math.floor(this.height / 2 + this.K1 * ooz * y);

      if (xp >= 0 && xp < this.width && yp >= 0 && yp < this.height) {
        const idx = xp + yp * this.width;
        if (this.overlapse) {
          if (ooz > this.zBuffer[idx]) {
            this.zBuffer[idx] = ooz;
            this.buffer[idx] = ch;
          }
        } else {
          if (z > this.zBuffer[idx]) {
            this.zBuffer[idx] = z;
            this.buffer[idx] = ch;
          }
        }
      }
    },
    renderCubes() {
      for (
        let cubeX = -this.cubeWidth;
        cubeX < this.cubeWidth;
        cubeX += this.incrementSpeed
      ) {
        for (
          let cubeY = -this.cubeWidth;
          cubeY < this.cubeWidth;
          cubeY += this.incrementSpeed
        ) {
          this.calculateForSurface(cubeX, cubeY, -this.cubeWidth, "+");
          this.calculateForSurface(this.cubeWidth, cubeY, cubeX, "~");
          this.calculateForSurface(-this.cubeWidth, cubeY, cubeX, "-");
          this.calculateForSurface(cubeX, cubeY, this.cubeWidth, "#");
          this.calculateForSurface(cubeX, -this.cubeWidth, cubeY, ";");
          this.calculateForSurface(cubeX, this.cubeWidth, cubeY, ".");
        }
      }
    },
    renderMath() {
      this.renderedMath = katex.renderToString(this.mathExpression, {
        throwOnError: false,
      });
    },
    updateDisplay() {
      let display = "";
      for (let k = 0; k < this.width * this.height; k++) {
        display += k % this.width ? this.buffer[k] : "\n";
      }

      this.display = display;
    },
    updateAnimation() {
      this.A += 0.05;
      this.B += 0.05;
      this.C += 0.01;
    },
    startAnimation() {
      const animate = () => {
        this.buffer.fill(this.backgroundASCIICode);
        this.zBuffer.fill(0);
        this.renderCubes();
        this.updateDisplay();
        this.updateAnimation();

        requestAnimationFrame(animate);
      };

      animate();
    },
    handleUpdateParameters(newParameters) {
      this.overlapse = newParameters.overlapse.value;
      this.incrementSpeed = newParameters.incrementSpeed.value;
      this.strokeColor = newParameters.strokeColor.value;
    },
    provideInitialValues() {
      this.$refs.cubeParameters.setInitialValues({
        overlapse: this.overlapse,
        incrementSpeed: this.incrementSpeed,
        strokeColor: this.strokeColor,
      });
    },
  },
  mounted() {
    this.startAnimation();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.renderMath();
  },
};
</script>
