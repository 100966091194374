<template>
  <div class="w-full bg-[#1b1b1b] p-2 px-3 rounded-md" @keydown="handleKeyDown">
    <p class="text-[#cb7676] flex space-x-1">
      <span> interface </span> <span class="text-[#b8a965]">SineWave</span>
      <span class="text-[#858585]">{</span>
    </p>
    <div
      v-for="(value, key) in parsedCode"
      :key="key"
      class="w-full flex text-[#b88a33] ml-6"
    >
      <p class="text-[#b8a965] flex">{{ key }} <span class="text-[#cb7676]">:</span></p>

      <Popper
        v-if="key === 'strokeColor'"
        :arrow="true"
        offsetDistance="5"
      >
        <button
          class="ml-1 w-3 h-3 border border-white"
          :class="{ hidden: key != 'strokeColor' }"
          :style="{
            background: key === 'strokeColor' ? editedCode.strokeColor.value : '#b88a33',
          }"
        ></button>
        <template #content>
          <ColorPicker canva_len="200" v-if="loaded" @SettedHex="HandleHex($event)" />
        </template>
      </Popper>
      <input
        ref="inputFields"
        v-model.number="editedCode[key].value"
        :readonly="key == 'strokeColor'"
        class="ml-1 bg-transparent focus:ring-0 outline-none focus:text-blue-400 z-10"
        @input="handleInput(key, $event)"
        @blur="handleBlur(key, $event)"
        @keyup.enter="$event.target.blur()"
        :name="key"
        autocomplete="off"
        maxlength="6"
      />
    </div>
    <p class="text-[#858585]">}</p>
  </div>
</template>

<script>
import { reactive } from "vue";
import ColorPicker from "@/components/ColorPicker.vue";
import Popper from "vue3-popper";

export default {
  components: {
    ColorPicker,
    Popper,
  },
  data() {
    return {
      activeIndex: 0,
      loaded: false,
      editedCode: reactive({
        amplitude: {
          value: 10,
          type: "numeric",
          limit: {
            lower: 1,
            higher: 200,
          },
        },
        multiplier: {
          value: 1.0,
          type: "decimal",
          limit: {
            lower: 0.1,
            higher: 10,
          },
        },
        maxLines: {
          value: 10,
          type: "numeric",
          limit: {
            lower: 1,
            higher: 100,
          },
        },
        interval: {
          value: 100,
          type: "numeric",
          limit: {
            lower: 1,
            higher: 1000,
          },
        },
        lineWidth: {
          value: 1,
          type: "decimal",
          limit: {
            lower: 0.01,
            higher: 3,
          },
        },
        strokeColor: {
          value: "#c3c3c3",
          type: "hexadecimal",
        },
      }),
    };
  },
  mounted() {
    this.$emit("request-initial-values");
    this.loaded = true;
  },
  computed: {
    parsedCode() {
      return this.editedCode;
    },
  },
  methods: {
    handleKeyDown(event) {
      const arrowUp = 38;
      const arrowDown = 40;

      if (event.keyCode === arrowUp || event.keyCode === arrowDown) {
        event.preventDefault();

        const keys = Object.keys(this.editedCode);

        if (event.keyCode === arrowUp) {
          this.activeIndex =
            this.activeIndex > 0 ? this.activeIndex - 1 : keys.length - 1;
        } else {
          this.activeIndex =
            this.activeIndex < keys.length - 1 ? this.activeIndex + 1 : 0;
        }

        const nextInput = this.$refs.inputFields.find(
          (input) => input.name === keys[this.activeIndex]
        );

        nextInput.focus();
      }
    },
    handleInput(key, event) {
      let inputValue = event.target.value;
      const { type, limit } = this.editedCode[key];
      let validFormat;

      if (type === "numeric") {
        validFormat = /^\d+$/;
      } else if (type === "decimal") {
        validFormat = /^(\d+(\.\d{0,2})?)?$/;
      }

      const numericValue = parseFloat(inputValue);

      if (!isNaN(numericValue)) {
        const { lower, higher } = limit;
        this.editedCode[key].value = Math.min(Math.max(numericValue, lower), higher);
      }

      if (!validFormat.test(inputValue)) {
        if (type === "decimal") {
          inputValue = inputValue.replace(/[^0-9.]/g, "");
        } else {
          inputValue = inputValue.replace(/[^0-9]/g, "");
        }
        this.editedCode[key].value = inputValue;
      }
    },
    handleBlur(key, event) {
      let inputValue = event.target.value;

      if (inputValue === "") {
        this.editedCode[key].value = this.editedCode[key].limit.lower;
      } else {
        const numericValue = parseFloat(inputValue);
        if (this.editedCode[key].limit) {
          const { lower, higher } = this.editedCode[key].limit;
          this.editedCode[key].value = Math.min(Math.max(numericValue, lower), higher);
        }
      }

      this.$emit("update-parameters", this.editedCode);
    },
    HandleHex(val) {
      this.editedCode.strokeColor.value = val;
      this.$emit("update-parameters", this.editedCode);
    },
    setInitialValues(initialValues) {
      this.editedCode.amplitude.value = initialValues.amplitude;
      this.editedCode.multiplier.value = initialValues.multiplier;
      this.editedCode.maxLines.value = initialValues.maxLines;
      this.editedCode.interval.value = initialValues.interval;
      this.editedCode.lineWidth.value = initialValues.lineWidth;
    },
  },
};
</script>
